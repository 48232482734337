import React from "react";
import { Container } from "react-bootstrap";
import { useStateContext } from "../../../reducers";
import {ReactComponent as AttendanceLogo} from '../../../assets/svg/calendar.svg';
import {ReactComponent as SummaryLogo} from '../../../assets/svg/summary.svg';
import MenuCard from "../../../components/MenuCard";
import { useNavigate } from "react-router-dom";

function View(props) {
    const { authState: {active_year}, menuState: {showSidebar} } = useStateContext();
    const navigate = useNavigate();
    const urlPrefix = "/attendance";

    return (
        <Container fluid className={`m-0 p-0 p-3 d-flex flex-wrap justify-content-center align-items-center vh-75 main-container${showSidebar ? " crop" : ""}`}>
            <MenuCard
                menuIcon={<AttendanceLogo className="menu-card-icon" />}
                title="Absensi"
                subtitle="Entri Data Kehadiran Siswa"
                description="Gunakan menu ini untuk memasukkan / memperbaharui data kehadiran siswa pada hari tertentu."
                onClick={() => navigate(`${urlPrefix}/record`)}
            />
            <MenuCard
                menuIcon={<SummaryLogo className="menu-card-icon" />}
                title="Rekapitulasi"
                subtitle="Lihat Data kehadiran Siswa"
                description={`Buka menu ini untuk melihat kehadiran siswa di tahun ajaran ${active_year}.`}
                onClick={() => {}}
            />
        </Container>
    );
}

export default View;
