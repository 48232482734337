import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useWindowDimensions } from "../utils/Common";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../reducers";

function TemplateEmptyView(props) {
    const {
        isMainMenu
    } = props;
    const { menuState: {showSidebar} } = useStateContext();
    const {height} = useWindowDimensions();
    const navigate = useNavigate();

    useEffect(() => {
        const newAuthState = JSON.parse(localStorage.getItem(process.env.REACT_APP_AUTH_PERSIST_STORAGE_KEY));

        if (!newAuthState || !newAuthState.username || newAuthState.username === "" || !newAuthState.hasPassword)
        {
            navigate("/login");
            return;
        }
    }, []);

    return (
        <Container fluid style={{height: height >= 800 ? "73vh" : height >= 540 ? "69vh" : "65vh"}} className={`m-0 p-0 p-3 main-container${showSidebar && isMainMenu ? " crop" : ""}`}>
            <h2 className="text-danger"><i>Under Construction...</i></h2>
        </Container>
    );
};

export default TemplateEmptyView;
