import React, { useEffect, useMemo, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import Dashboard from "./pages/Dashboard";
import MainNavbar from './components/MainNavbar';
import DefaultBreadcrumb from './components/Breadcrumb';
// import { Menu } from './utils/Menu';
import { PageFooter } from './components/PageFooter';
import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
import "../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css";
import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-notifications/styles/bootstrap5.css';
import "../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap5.css";
import {registerLicense} from '@syncfusion/ej2-base';
import Login from './pages/Auth/Login';
import authReducer, { authInitialState } from './reducers/auth';
import ChangePassword from './pages/Auth/ChangePassword';
import ApiCaller from './components/ApiTemplate';
import StateContext, { usePersistedReducer } from './reducers';
import salesReducer, { salesInitialState } from './reducers/sales';
import { Alert } from 'react-bootstrap';
import menuReducer, { menuInitialState } from './reducers/menu';
import Sidebar from './components/Sidebar';
import { Menu } from './utils/Menu';
import Logout from './pages/Auth/Logout';
import Tests from './pages/Student/Scoring/Tests';
import Questions from './pages/Student/Scoring/Questions';
import Answers from './pages/Student/Scoring/Answers';
import TestsStudent from './pages/Student/Scoring/TestsStudent';
import ScoreCheck from './pages/Student/Scoring/ScoreCheck';
import ScoreCheckStudents from './pages/Student/Scoring/ScoreCheckStudents';
import ScoreCheckStudentResults from './pages/Student/Scoring/ScoreCheckStudentResults';
import AttendanceRecord from './pages/Student/Attendance/Record';
import PublicationClass from './pages/Publication/Class';

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

function App() {
    const { state: authState, dispatch: setAuthState } = usePersistedReducer(authReducer, authInitialState, process.env.REACT_APP_AUTH_PERSIST_STORAGE_KEY);
    const { state: salesState, dispatch: setSalesState } = usePersistedReducer(salesReducer, salesInitialState, process.env.REACT_APP_SALES_PERSIST_STORAGE_KEY);
    const { state: menuState, dispatch: setMenuState } = usePersistedReducer(menuReducer, menuInitialState, process.env.REACT_APP_SALES_PERSIST_STORAGE_KEY);
    const [ showMenu, setShowMenu ] = useState(true);
    const [ showSidebar, setShowSidebar ] = useState(menuInitialState?.showSidebar ?? false);

    const apiCaller = useMemo(() => {
        return new ApiCaller({authState});
    }, [authState]);

    const providerState = useMemo(() => {
        return {
            authState,
            setAuthState,
            salesState,
            setSalesState,
            menuState,
            setMenuState,
            apiCaller,
        };
    }, [apiCaller, authState, setAuthState, salesState, setSalesState, menuState, setMenuState]);

    useEffect(() => {
        // console.log({authState});
        setShowMenu(authState && authState.username && authState.hasPassword);
    }, [authState, setAuthState]);

    const routes = useMemo(() => (
        // Menu.filter((m) => authState[process.env.REACT_APP_BUILD_ID] && authState.menus?.length && authState.menus.filter(am => m.children.filter(c => c.code === am.kode_menu).length > 0).length > 0)
        Menu
            .map((m, idx) => {
                return (
                    <Route key={`route_${idx}`} path={m.path} element={m.component} />
                );
            })
    ), []);

    useEffect(() => {
        // console.log({menuState});
        setShowSidebar(menuState && menuState.showSidebar);
    }, [menuState]);

    return (
        <StateContext.Provider value={providerState}>
            <BrowserRouter>
                <Sidebar show={showSidebar} />
                <MainNavbar />
            {
                !authState[process.env.REACT_APP_BUILD_ID] ?
                <Alert variant="danger">Update aplikasi ditemukan. Mohon tekan <b>CTRL+F5</b>, <b>logout</b>, lalu <b>login ulang</b> agar aplikasi dapat digunakan kembali.</Alert>
                :
                null
            }
            {
                showMenu ?
                <DefaultBreadcrumb
                    mapper={[
                        {
                            url: "/attendance/record",
                            title: "Absensi Jam Pelajaran",
                        },
                        {
                            url: "/evaluation/tests",
                            title: "Pengaturan Ujian",
                        },
                        {
                            url: "/evaluation/tests/questions",
                            title: "Soal",
                        },
                        {
                            url: "/evaluation/preparations",
                            title: "Pemilihan Ujian",
                        },
                        {
                            url: "/evaluation/preparations/answers",
                            title: "Pengerjaan Ujian",
                        },
                        {
                            url: "/evaluation/check",
                            title: "Pemilihan Hasil Ujian",
                        },
                        {
                            url: "/evaluation/check/students",
                            title: "Data Murid",
                        },
                        {
                            url: "/evaluation/check/students/results",
                            title: "Hasil Ujian",
                        },
                    ]}
                />
                :
                null
            }
                <Routes>
                    <Route path="/" element={showMenu ? <Dashboard /> : <Navigate to="/login" replace />} />
                    {
                        routes
                    }
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" action={() => Logout()} />
                    <Route path="/attendance/record" element={<AttendanceRecord />} />
                    <Route path="/evaluation/tests" element={<Tests />} />
                    <Route path="/evaluation/tests/questions" element={<Questions />} />
                    <Route path="/evaluation/preparations" element={<TestsStudent />} />
                    <Route path="/evaluation/preparations/answers" element={<Answers />} />
                    <Route path="/evaluation/check" element={<ScoreCheck />} />
                    <Route path="/evaluation/check/students" element={<ScoreCheckStudents />} />
                    <Route path="/evaluation/check/students/results" element={<ScoreCheckStudentResults />} />
                </Routes>
                <PageFooter />
            </BrowserRouter>
        </StateContext.Provider>
    );
}

export default App;
