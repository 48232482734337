import React from "react";
import { Container } from "react-bootstrap";
import { useStateContext } from "../../../reducers";
import {ReactComponent as ClassroomLogo} from '../../../assets/svg/classroom.svg';
import {ReactComponent as UniformLogo} from '../../../assets/svg/uniform.svg';
import {ReactComponent as BookLogo} from '../../../assets/svg/book.svg';
import MenuCard from "../../../components/MenuCard";

function View(props) {
    const { authState: {active_year}, menuState: {showSidebar} } = useStateContext();


    return (
        <Container fluid className={`m-0 p-0 p-3 d-flex flex-wrap justify-content-center align-items-center vh-75 main-container${showSidebar ? " crop" : ""}`}>
            <MenuCard
                menuIcon={<ClassroomLogo className="menu-card-icon" />}
                title="Kelas"
                subtitle="Manajemen Kelas"
                description={`Gunakan menu ini untuk mengatur data kelas untuk tahun ajaran ${active_year}.`}
                onClick={() => {}}
            />
            <MenuCard
                menuIcon={<UniformLogo className="menu-card-icon" />}
                title="Pembagian Kelas"
                subtitle="Pengaturan Kelas Siswa"
                description={`Gunakan menu ini untuk mengatur alokasi siswa di kelas untuk tahun ajaran ${active_year}.`}
                onClick={() => {}}
            />
            <MenuCard
                menuIcon={<BookLogo className="menu-card-icon" />}
                title="Jadwal Pelajaran"
                subtitle="Pengaturan Jadwal Pelajaran Kelas"
                description={`Gunakan menu ini untuk mengatur alokasi pelajaran di kelas untuk tahun ajaran ${active_year}.`}
                onClick={() => {}}
            />
        </Container>
    );
}

export default View;
