import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';

function TooltipLabel(props) {
    const {
        title,
        children,
    } = props;

    const popover = (
        <Tooltip>
            {title}
        </Tooltip>
    )

    return (
        <OverlayTrigger placement="top" overlay={popover}>
            <span>
                {children}
            </span>
        </OverlayTrigger>
    );
}

TooltipLabel.propTypes = {
    title: PropTypes.string.isRequired,
};

export default TooltipLabel;
