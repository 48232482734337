import React, { useCallback, useMemo, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import DefaultGrid from "../../../components/DefaultGrid";
import SearchInput from "../../../components/SearchInput";
import TooltipButton from "../../../components/TooltipButton";
import { useStateContext } from "../../../reducers";

function Record(props) {
    const { apiCaller, menuState: {showSidebar} } = useStateContext();
    const [ loading, setLoading ] = useState(false);
    const [ selectedClass, setSelectedClass ] = useState(null);
    const [ selectedSubject, setSelectedSubject ] = useState(null);
    const [ reloadSubject, setReloadSubject ] = useState(0);
    const [ reload, setReload ] = useState(0);

    const loadDataClasses = useCallback((val, valueField, labelField, callback, errorCallback) => {
        setLoading(true);

        apiCaller.get(`classes/search/name${val && val !== "" ? `?name=${val}` : ""}`)
            .then(response => {
                callback(response.data.data.map(item => {
                    return {
                        value: item[valueField],
                        label: item[labelField],
                    };
                }));
                setLoading(false);
            })
            .catch((error) => {
                console.log({error});
                errorCallback();
                setLoading(false);
            })
        ;
    }, [apiCaller]);

    const onClassSelected = useCallback((val) => {
        if (!val)
        {
            setSelectedClass(null);
            return;
        }

        setSelectedClass({
            ucode: val?.value,
            nama: val?.label,
        });
        // console.log({reloadSubject});
        setReloadSubject(reloadSubject === 0 ? 1 : 0);
    }, [reloadSubject]);

    const loadDataSubjects = useCallback((classId, val, valueField, labelField, callback, errorCallback) => {
        setLoading(true);

        if (!classId)
        {
            errorCallback();
            setLoading(false);
            return;
        }

        apiCaller.get(`subjects/search/class?classId=${classId}${val && val !== "" ? `&name=${val}` : ""}`)
            .then(response => {
                callback(response.data.data.map(item => {
                    return {
                        value: item[valueField],
                        label: item[labelField],
                        ucode_kelas_pelajaran: item.ucode_kelas_pelajaran,
                    };
                }));
                setLoading(false);
            })
            .catch((error) => {
                console.log({error});
                errorCallback();
                setLoading(false);
            })
        ;
    }, [apiCaller]);

    const onSubjectSelected = useCallback((val) => {
        if (!val)
        {
            setSelectedSubject(null);
            return;
        }

        setSelectedSubject({
            ucode: val?.value,
            nama: val?.label,
            ucode_kelas_pelajaran: val?.ucode_kelas_pelajaran,
        });
    }, []);

    const subjectSearchInput = useMemo(() => {
        return (
            <SearchInput
                title="Pelajaran"
                valueField="ucode"
                labelField="nama"
                loadOptions={(val, valueField, labelField, callback, errorCallback) => loadDataSubjects(selectedClass?.ucode, val, valueField, labelField, callback, errorCallback)}
                onChange={(val) => onSubjectSelected(val)}
                required={true}
                disabled={selectedClass === null}
                shouldReload={reloadSubject}
            />
        )
    }, [loadDataSubjects, onSubjectSelected, selectedClass, reloadSubject]);

    const columns = useMemo(() => [
        {
            Header: "Kehadiran",
            id: "action",
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                    <Form.Check />
                    </div>
                )
            },
            maxWidth: 40,
        },
        {
            Header: "No. Induk",
            accessor: "kode",
            disableFilters: true,
            minWidth: 100,
            width: 100,
        },
        {
            Header: "Nama Siswa",
            accessor: "nama",
            disableFilters: true,
            maxWidth: 300,
            width: 300,
        },
    ], []);

    const grid = useMemo(() => {
        if (!selectedClass)
            return null;

        return (
            <DefaultGrid
                columns={columns}
                url="students/search/class"
                reload={reload}
                additionalParams={`classId=${selectedClass?.ucode}`}
            />
        );
    }, [reload, columns, selectedClass]);

    return (
        <Container fluid className={`m-0 p-0 p-3 main-container${showSidebar ? " crop" : ""}`}>
            <Row className="m-0 p-0">
                <Col lg={3} md={6} sm={12} className="m-0 p-0 px-2">
                    <SearchInput
                        title="Kelas"
                        valueField="ucode"
                        labelField="nama"
                        loadOptions={(val, valueField, labelField, callback, errorCallback) => loadDataClasses(val, valueField, labelField, callback, errorCallback)}
                        onChange={(val) => onClassSelected(val)}
                        required={true}
                    />
                </Col>
                <Col lg={3} md={6} sm={12} className="m-0 p-0 px-2">
                {
                    subjectSearchInput
                }
                </Col>
            </Row>
        {
            selectedSubject ?
            <>
                <Row className="m-0 p-0">
                    <Col lg={4} md={6} sm={12} className="m-0 p-0">
                        <Row className="m-0 p-0">
                            <Col xs={4} className="m-0 p-0">
                                <span>Tanggal/Jam</span>
                            </Col>
                            <Col xs={8} className="m-0 p-0">
                                <span className="text-break">: <b>22-03-2023/10:00</b></span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="m-0 p-0">
                    <Col lg={4} md={6} sm={12} className="m-0 p-0">
                        <Row className="m-0 p-0">
                            <Col xs={4} className="m-0 p-0">
                                <span>Guru Pengajar</span>
                            </Col>
                            <Col xs={8} className="m-0 p-0">
                                <span className="text-break">: <b>Rudi Darmanto</b></span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="m-0 p-0 mt-3">
                    <Col lg={12} className="m-0 p-0">
                        <TooltipButton
                            id="reload-attendance"
                            onClick={() => setReload(reload === 0 ? 1 : 0)}
                            variant="outline-dark"
                            size="sm"
                            title="Refresh Data"
                            disabled={!selectedClass || !selectedSubject || loading}
                        >
                            <i className="fas fa-sync" />
                        </TooltipButton>
                    </Col>
                </Row>
            </>
            :
            null
        }
        {
            selectedSubject ?
            <Row className="m-0 p-0 mt-2">
                <Col lg={12} className="m-0 p-0">
                {
                    grid
                }
                </Col>
            </Row>
            :
            null
        }
        </Container>
    );
};

export default Record;
