import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DefaultGrid from "../../../components/DefaultGrid";
import SearchInput from "../../../components/SearchInput";
import TooltipButton from "../../../components/TooltipButton";
import { useStateContext } from "../../../reducers";
import { useNavigate } from "react-router-dom";

function Tests(props) {
    const { apiCaller, menuState: {showSidebar} } = useStateContext();
    const [ loading, setLoading ] = useState(false);
    const [ selectedClass, setSelectedClass ] = useState(null);
    const [ selectedSubject, setSelectedSubject ] = useState(null);
    const [ reloadSubject, setReloadSubject ] = useState(0);
    const [ reloadGrid, setReloadGrid ] = useState(0);
    const navigate = useNavigate();

    const loadDataClasses = useCallback((val, valueField, labelField, callback, errorCallback) => {
        setLoading(true);

        apiCaller.get(`classes/search/name${val && val !== "" ? `?name=${val}` : ""}`)
            .then(response => {
                callback(response.data.data.map(item => {
                    return {
                        value: item[valueField],
                        label: item[labelField],
                    };
                }));
                setLoading(false);
            })
            .catch((error) => {
                console.log({error});
                errorCallback();
                setLoading(false);
            })
        ;
    }, [apiCaller]);

    const onClassSelected = useCallback((val) => {
        if (!val)
        {
            setSelectedClass(null);
            return;
        }

        setSelectedClass({
            ucode: val?.value,
            nama: val?.label,
        });
        // console.log({reloadSubject});
        setReloadSubject(reloadSubject === 0 ? 1 : 0);
    }, [reloadSubject]);

    // useEffect(() => console.log({selectedClass}), [selectedClass]);

    const loadDataSubjects = useCallback((classId, val, valueField, labelField, callback, errorCallback) => {
        setLoading(true);

        if (!classId)
        {
            errorCallback();
            setLoading(false);
            return;
        }

        apiCaller.get(`subjects/search/class?classId=${classId}${val && val !== "" ? `&name=${val}` : ""}`)
            .then(response => {
                callback(response.data.data.map(item => {
                    return {
                        value: item[valueField],
                        label: item[labelField],
                        ucode_kelas_pelajaran: item.ucode_kelas_pelajaran,
                    };
                }));
                setLoading(false);
            })
            .catch((error) => {
                console.log({error});
                errorCallback();
                setLoading(false);
            })
        ;
    }, [apiCaller]);

    const onSubjectSelected = useCallback((val) => {
        if (!val)
        {
            setSelectedSubject(null);
            return;
        }

        setSelectedSubject({
            ucode: val?.value,
            nama: val?.label,
            ucode_kelas_pelajaran: val?.ucode_kelas_pelajaran,
        });
    }, []);

    const subjectSearchInput = useMemo(() => {
        console.log({selectedClass, reloadSubject});
        return (
            <SearchInput
                title="Pelajaran"
                valueField="ucode"
                labelField="nama"
                loadOptions={(val, valueField, labelField, callback, errorCallback) => loadDataSubjects(selectedClass?.ucode, val, valueField, labelField, callback, errorCallback)}
                onChange={(val) => onSubjectSelected(val)}
                required={true}
                disabled={selectedClass === null}
                shouldReload={reloadSubject}
            />
        )
    }, [loadDataSubjects, onSubjectSelected, selectedClass, reloadSubject]);

    const columns = useMemo(() => [
        {
            Header: "Tipe",
            accessor: "tipe_penilaian",
            disableFilters: true,
        },
        {
            Header: "Tanggal",
            id: "tanggal_ujian",
            disableFilters: true,
            accessor: (row) => moment(row.tanggal_ujian).format("DD-MM-YYYY"),
        },
        {
            Header: "Durasi (menit)",
            accessor: "durasi",
            disableFilters: true,
        },
        {
            Header: "Status",
            accessor: "status",
            disableFilters: true,
        },
        {
            Header: "",
            id: "action",
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                    {
                        <TooltipButton
                            title="Update Soal Ujian"
                            size="sm"
                            variant="outline-primary"
                            className="me-2"
                            onClick={() => {
                                navigate(`/evaluation/tests/questions?classId=${selectedClass.ucode}&className=${selectedClass.nama}&subjectId=${selectedSubject.ucode}&subjectName=${selectedSubject.nama}&testId=${original.ucode}&testDate=${original.tanggal_ujian}&duration=${original.durasi}`);
                            }}
                        >
                            <i className="fas fa-edit" />
                        </TooltipButton>
                    }
                    </div>
                )
            },
            maxWidth: 100,
        },
    ], [navigate, selectedClass, selectedSubject]);

    const grid = useMemo(() => {
        if (!selectedSubject?.ucode_kelas_pelajaran)
            return null;

        return (
            <DefaultGrid
                columns={columns}
                url="tests"
                reload={reloadGrid}
                additionalParams={`subjectId=${selectedSubject?.ucode_kelas_pelajaran}`}
            />
        );
    }, [reloadGrid, columns, selectedSubject?.ucode_kelas_pelajaran]);

    return (
        <Container fluid className={`m-0 p-0 p-3 main-container${showSidebar ? " crop" : ""}`}>
            <Row className="m-0 p-0">
                <Col lg={3} md={6} sm={12} className="m-0 p-0 px-2">
                    <SearchInput
                        title="Kelas"
                        valueField="ucode"
                        labelField="nama"
                        loadOptions={(val, valueField, labelField, callback, errorCallback) => loadDataClasses(val, valueField, labelField, callback, errorCallback)}
                        onChange={(val) => onClassSelected(val)}
                        required={true}
                    />
                </Col>
                <Col lg={3} md={6} sm={12} className="m-0 p-0 px-2">
                {
                    subjectSearchInput
                }
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={12} className="m-0 p-0">
                    <TooltipButton
                        id="add-tests"
                        onClick={() => {}}
                        variant="primary"
                        size="sm"
                        title="Tambah Ujian"
                        disabled={!selectedClass || !selectedSubject || loading}
                    >
                        <i className="fas fa-plus" />
                    </TooltipButton>
                {
                    selectedSubject ?
                    <TooltipButton
                        id="reload-tests"
                        onClick={() => setReloadGrid(reloadGrid ? 0 : 1)}
                        variant="outline-dark"
                        size="sm"
                        title="Refresh Data"
                        disabled={!selectedClass || !selectedSubject || loading}
                        className="ms-2"
                    >
                        <i className="fas fa-sync" />
                    </TooltipButton>
                    :
                    null
                }
                </Col>
            </Row>
        {
            selectedSubject ?
            <Row className="m-0 p-0 mt-2">
                <Col lg={12} className="m-0 p-0">
                {
                    grid
                }
                </Col>
            </Row>
            :
            null
        }
        </Container>
    );
};

export default Tests;