import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../reducers';

function Logout() {
    const { authState, setAuthState, apiCaller } = useStateContext();
    const navigate = useNavigate();

    const handleLogout = useCallback(() => {
        apiCaller.post(`users/logout`, {...authState})
            .then((response) => {
                console.log({response});
                setAuthState({type: "logout"});
                navigate('/');
            })
            .catch((error) => {
                console.log({error});
            })
        ;
    }, [apiCaller, setAuthState, authState, navigate]);
    
    return handleLogout;
}

export default Logout;
