import UserSettings from "../pages/Admin/Masters";
import User from "../pages/Admin/Masters/User";
import MenuTab from "../pages/Admin/Masters/Menu";
import Role from "../pages/Admin/Masters/Role";
import AdminBranches from "../pages/Admin/Branches";
import BranchBranch from "../pages/Admin/Branches/Branch";
import AdminStudents from "../pages/Admin/Students";
import StudentCommon from "../pages/Admin/Students/Common";
import StudentSubject from "../pages/Admin/Students/Subject";
import StudentStudent from "../pages/Admin/Students/Student";
import AdminEmployees from "../pages/Admin/Employees";
import EmployeeEmployee from "../pages/Admin/Employees/Employee";
import AdminPayments from "../pages/Admin/Payments";
import Classes from "../pages/Student/Classes";
import Attendance from "../pages/Student/Attendance";
import Scoring from "../pages/Student/Scoring";
import TemplateEmptyView from "../pages/TemplateEmptyView";
import PublicationClass from '../pages/Publication/Class';
import PublicationGlobal from '../pages/Publication/Global';

export const Menu = [
    {
        group: "Profile",
        subgroup: "Ubah Password",
        title: "Ubah Password",
        path: "/change-password",
        component: <TemplateEmptyView isMainMenu={true} />,
        icon: "fas fa-key",
        children: []
    },
    {
        group: "Profile",
        subgroup: "Notifikasi",
        title: "Notifikasi",
        path: "/notification",
        component: <TemplateEmptyView isMainMenu={true} />,
        icon: "far fa-bell",
        children: []
    },
    {
        group: "Profile",
        subgroup: "Logout",
        title: "Logout",
        path: "/logout",
        component: <></>,
        icon: "fas fa-sign-out-alt",
        children: []
    },
    {
        group: "User",
        subgroup: "Pengaturan",
        title: "Pengaturan User",
        path: "/user",
        component: <UserSettings />,
        icon: "fas fa-cog",
        children: [
            {
                title: "User",
                component: (props) => <User {...props} />,
                code: "USER-USER",
            },
            // {
            //     title: "Menu",
            //     component: (props) => <MenuTab {...props} />,
            //     code: "USER-MENU",
            // },
            {
                title: "Role",
                component: (props) => <Role {...props} />,
                code: "USER-ROLE",
            },
        ]
    },
    {
        group: "Master",
        subgroup: "Cabang",
        title: "Cabang",
        path: "/branch",
        component: <AdminBranches />,
        icon: "far fa-building",
        children: [
            {
                title: "Cabang",
                component: (props) => <BranchBranch {...props} />,
                code: "ADMIN-DIVISI",
            },
        ]
    },
    {
        group: "Master",
        subgroup: "Siswa",
        title: "Administrasi Siswa",
        path: "/school-year",
        component: <AdminStudents />,
        icon: "fas fa-graduation-cap",
        children: [
            {
                title: "Umum",
                component: (props) => <StudentCommon {...props} />,
                code: "SISWA-UMUM",
            },
            {
                title: "Pelajaran",
                component: (props) => <StudentSubject {...props} />,
                code: "SISWA-PELAJARAN",
            },
            {
                title: "Siswa",
                component: (props) => <StudentStudent {...props} />,
                code: "SISWA-SISWA",
            },
            {
                title: "Mutasi Siswa",
                component: (props) => <TemplateEmptyView />,
                code: "SISWA-KELUAR",
            },
        ]
    },
    {
        group: "Master",
        subgroup: "Kepegawaian",
        title: "Kepegawaian",
        path: "/employee",
        component: <AdminEmployees />,
        icon: "far fa-id-badge",
        children: [
            {
                title: "Pegawai",
                component: (props) => <EmployeeEmployee {...props} />,
                code: "KRY-KRY",
            },
            {
                title: "Jabatan",
                component: (props) => <TemplateEmptyView />,
                code: "KRY-JABATAN",
            },
        ]
    },
    {
        group: "Administrasi",
        subgroup: "Pembayaran",
        title: "Pembayaran",
        path: "/payment",
        component: <AdminPayments />,
        icon: "far fa-credit-card",
        children: [
            {
                title: "Penerimaan Pembayaran",
                component: (props) => <TemplateEmptyView />,
                code: "BAYAR-TERIMA",
            },
            {
                title: "Alokasi Pembayaran",
                component: (props) => <TemplateEmptyView />,
                code: "BAYAR-ALOKASI",
            },
        ]
    },
    {
        group: "Kesiswaan",
        subgroup: "Pengaturan Kelas",
        title: "Pengaturan Kelas",
        path: "/class-arrangement",
        component: <Classes />,
        icon: "fas fa-cog",
        children: []
    },
    {
        group: "Kesiswaan",
        subgroup: "Absensi",
        title: "Absensi Siswa",
        path: "/attendance",
        component: <Attendance />,
        icon: "far fa-check-square",
        children: []
    },
    {
        group: "Kesiswaan",
        subgroup: "Evaluasi",
        title: "Evaluasi Siswa",
        path: "/evaluation",
        component: <Scoring />,
        icon: "fas fa-pen",
        children: []
    },
    {
        group: "Kesiswaan",
        subgroup: "Laporan",
        title: "Laporan Kesiswaan",
        path: "/student-report",
        component: <TemplateEmptyView isMainMenu={true} />,
        icon: "far fa-list-alt",
        children: []
    },
    {
        group: "Publikasi",
        subgroup: "Kelas",
        title: "Pengumuman Kelas",
        path: "/publication-class",
        component: <PublicationClass />,
        icon: "far fa-list-alt",
        children: []
    },
    {
        group: "Publikasi",
        subgroup: "Umum",
        title: "Pengumuman",
        path: "/publication-global",
        component: <PublicationGlobal />,
        icon: "far fa-list-alt",
        children: []
    },
];