import React from 'react';
import { OverlayTrigger, Button, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';

function TooltipButton(props) {
    const {
        title,
        children,
        ...etc
    } = props;

    const popover = (
        <Tooltip>
            {title}
        </Tooltip>
    )

    return (
        <OverlayTrigger placement="bottom" overlay={popover}>
            <Button
                {...etc}
            >
                {children}
            </Button>
        </OverlayTrigger>
    );
}

TooltipButton.propTypes = {
    title: PropTypes.string.isRequired,
};

export default TooltipButton;
