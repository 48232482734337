export const salesInitialState = {unhandledSOCount: 0, unhandledDOCount: 0};

export default function salesReducer(state, action) {
    switch (action.type) {
        case 'refresh-so':
            return {...state, unhandledSOCount: action.unhandledSOCount};
        case 'refresh-do':
            return {...state, unhandledDOCount: action.unhandledDOCount};
        case 'refresh-pr':
            return {...state, unhandledPRCount: action.unhandledPRCount};
        default:
            throw new Error();
    }
}
