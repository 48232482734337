export const authInitialState = {username: null, emp_name: null, div_code: null, token: null, hasPassword: false, level: 0, menus: [], [process.env.REACT_APP_BUILD_ID]: 1, active_year_id: null, active_year: "yyyy/yyyy"};

export default function authReducer(state, action) {
    switch (action.type) {
        case 'login':
            // console.log({action});
            return {...action, level: parseInt(action.level), [process.env.REACT_APP_BUILD_ID]: 1};
        case 'refresh': 
            return {...action, level: parseInt(action.level), [process.env.REACT_APP_BUILD_ID]: 1};
        case 'logout':
            if('caches' in window)
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach(name => {
                        caches.delete(name);
                    });
                });
            
            localStorage.clear();
            return {...authInitialState};
        default:
            throw new Error();
    }
}
