import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import DefaultGrid from "../../../components/DefaultGrid";
import TooltipButton from "../../../components/TooltipButton";
import { useStateContext } from "../../../reducers";
import { useLocation } from "react-router-dom";

function Questions(props) {
    const location = useLocation();
    const [ questionParams, setQuestionParams ] = useState(null);
    const { menuState: {showSidebar} } = useStateContext();
    const [ reload, setReload ] = useState(0);
    const [ totalScore, setTotalScore ] = useState(0);
    
    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(location.search));
        setQuestionParams({
            ...params
        });
    }, [location]);

    const columns = useMemo(() => [
        {
            Header: "Soal",
            accessor: "soal",
            disableFilters: true,
            minWidth: 300,
            width: 300,
        },
        {
            Header: "Tipe Soal",
            accessor: "tipe_soal",
            disableFilters: true,
            maxWidth: 100,
            width: 100,
        },
        {
            Header: "Nilai",
            accessor: "nilai",
            disableFilters: true,
            maxWidth: 80,
            width: 80,
        },
        {
            Header: "Status",
            accessor: "status",
            disableFilters: true,
            maxWidth: 80,
            width: 80,
        },
        {
            Header: "",
            id: "action",
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <TooltipButton
                            title="Update Soal"
                            size="sm"
                            variant="outline-primary"
                            onClick={() => {}}
                        >
                            <i className="fas fa-edit" />
                        </TooltipButton>
                        <TooltipButton
                            title="Hapus Soal"
                            size="sm"
                            variant="outline-danger"
                            className="ms-2"
                            onClick={() => {}}
                        >
                            <i className="fas fa-trash-alt" />
                        </TooltipButton>
                    </div>
                )
            },
            maxWidth: 100,
        },
    ], []);

    const grid = useMemo(() => {
        if (!questionParams?.testId)
            return null;

        return (
            <DefaultGrid
                columns={columns}
                url="tests/questions"
                reload={reload}
                additionalParams={`testId=${questionParams?.testId}`}
                reloadCallback={(data) => setTotalScore(data.map(d => d.nilai).reduce((a, b) => parseFloat(a) + parseFloat(b), 0))}
            />
        );
    }, [reload, columns, questionParams?.testId]);

    return (
        <Container fluid className={`m-0 p-0 p-3 main-container${showSidebar ? " crop" : ""}`}>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Kelas</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.className ?? "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Pelajaran</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.subjectName ?? "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Tanggal</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.testDate ? moment(questionParams.testDate).format("DD-MM-YYYY") : "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Durasi</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.duration ?? "-"} menit</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Total Nilai</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{totalScore}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-4">
                <Col lg={12} className="m-0 p-0">
                    <TooltipButton
                        id="add-question"
                        onClick={() => {}}
                        variant="primary"
                        size="sm"
                        title="Tambah Soal Ujian"
                    >
                        <i className="fas fa-plus" />
                    </TooltipButton>
                    <TooltipButton
                        id="reload-questions"
                        onClick={() => setReload(reload === 1 ? 0 : 1)}
                        variant="outline-dark"
                        size="sm"
                        title="Refresh Data"
                        className="ms-1"
                    >
                        <i className="fas fa-sync" />
                    </TooltipButton>
                    <Button size="sm" variant="outline-success" className="ms-1" onClick={() => {}}><i className="fas fa-file-download me-2" />Download Template</Button>
                    <Button size="sm" variant="light border border-dark" className="ms-1" onClick={() => {}}><i className="fas fa-file-upload me-2" />Upload Soal</Button>
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-2">
                <Col lg={12} className="m-0 p-0">
                {
                    grid
                }
                </Col>
            </Row>
        </Container>
    );
};

export default Questions;
