import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

function Loader(props) {
    return (
        <Modal
            show={true}
            size="lg"
            className="d-flex justify-content-center align-items-center loader w-100 h-100"
            contentClassName="d-flex justify-content-center align-items-center bg-transparent border-0 loader w-100 h-100"
        >
            <Modal.Body>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Modal.Body>
        </Modal>
    );
}

export default Loader;
