import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useStateContext } from "../../reducers";

function Global(props) {
    const { menuState: {showSidebar} } = useStateContext();

    return (
        <Container fluid className={`m-0 p-0 p-3 main-container${showSidebar ? " crop" : ""}`}>
            <Row className="m-0 p-0 mt-2">
                <Col lg={12} className="m-0 p-0">
                    <Form.Control
                        as="textarea"
                        rows={7}
                    />
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-4">
                <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                    <Button
                        variant="primary"
                        size="sm"
                    >
                        <i className="fas fa-paper-plane me-2" />Kirim Pengumuman
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Global;
