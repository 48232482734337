import React from 'react';
import { Container } from 'react-bootstrap';
import { useStateContext } from '../reducers';

function Dashboard(props) {
    const { menuState: {showSidebar} } = useStateContext();

    return (
        <Container fluid className={`m-0 p-0 p-3 main-container${showSidebar ? " crop" : ""}`}>
            
        </Container>
    );
}

export default Dashboard;
