import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TooltipButton from "../../../components/TooltipButton";
import { useStateContext } from "../../../reducers";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultGrid from "../../../components/DefaultGrid";

function ScoreCheckStudents(props) {
    const location = useLocation();
    const [ questionParams, setQuestionParams ] = useState(null);
    const [ reload, setReload ] = useState(0);
    const { menuState: {showSidebar} } = useStateContext();
    const navigate = useNavigate();
    
    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(location.search));
        setQuestionParams({
            ...params
        });
    }, [location]);

    const columns = useMemo(() => [
        {
            Header: "No. Induk",
            accessor: "kode",
            disableFilters: true,
            minWidth: 300,
            width: 300,
        },
        {
            Header: "Nama",
            accessor: "nama",
            disableFilters: true,
            maxWidth: 100,
            width: 100,
        },
        {
            Header: "Nilai",
            accessor: "nilai",
            disableFilters: true,
            maxWidth: 80,
            width: 80,
        },
        {
            Header: "",
            id: "action",
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <TooltipButton
                            title="Update Nilai"
                            size="sm"
                            variant="outline-primary"
                            onClick={() => {
                                navigate(`/evaluation/check/students/results?studentId=${original.ucode_kelas_murid}&studentCode=${original.kode}&studentName=${original.nama}&className=${questionParams.className}&subjectName=${questionParams.subjectName}&testId=${questionParams.testId}&testDate=${questionParams.testDate}&duration=${questionParams.duration}`)
                            }}
                        >
                            <i className="fas fa-edit" />
                        </TooltipButton>
                    </div>
                )
            },
            maxWidth: 50,
        },
    ], [navigate, questionParams]);

    const grid = useMemo(() => {
        if (!questionParams?.classId)
            return null;

        return (
            <DefaultGrid
                columns={columns}
                url="students/search/class"
                reload={reload}
                additionalParams={`classId=${questionParams?.classId}`}
            />
        );
    }, [reload, columns, questionParams?.classId]);

    return (
        <Container fluid className={`m-0 p-0 p-3 main-container${showSidebar ? " crop" : ""}`}>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Kelas</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.className ?? "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Pelajaran</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.subjectName ?? "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Tanggal</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.testDate ? moment(questionParams.testDate).format("DD-MM-YYYY") : "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Durasi</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.duration ?? "-"} menit</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-4">
                <Col lg={12} className="m-0 p-0">
                    <TooltipButton
                        id="reload-questions"
                        onClick={() => setReload(reload === 1 ? 0 : 1)}
                        variant="outline-dark"
                        size="sm"
                        title="Refresh Data"
                        className="ms-1"
                    >
                        <i className="fas fa-sync" />
                    </TooltipButton>
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-4">
                <Col lg={12} className="m-0 p-0">
                {
                    grid
                }
                </Col>
            </Row>
        </Container>
    );
};

export default ScoreCheckStudents;
