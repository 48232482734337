import React from 'react';
import { CDBFooter, CDBFooterLink, CDBBtn, CDBIcon, CDBContainer, CDBBox } from 'cdbreact';
import { Button, Container } from 'react-bootstrap';
import { useWindowDimensions } from '../utils/Common';

export const PageFooter = () => {
    const date = new Date();
    const { width, height } = useWindowDimensions();

    return (
        // <CDBFooter className="pb-3 px-2">
        //     <Container className="shadow rounded bg-white">
        //         <CDBBox
        //             display="flex"  
        //             justifyContent="center"
        //             alignItems="center"
        //             className="mx-auto py-2 flex-wrap px-2"
        //         >
        //             {/* <div className="d-flex justify-content-start align-items-center" style={{width: "30%"}}>
        //                 <a href="/" className="d-flex align-items-center p-0 text-dark">
        //                     <span className="ml-4 h5 mb-0 font-weight-bold" style={{fontSize: "0.9rem"}}>Novitate</span>
        //                 </a>
        //             </div> */}
        //             <div className="d-flex justify-content-start align-items-center w-75">
        //                 <span className="text-left font-sm-custom">&copy; <a>Novitate</a>, {date.getFullYear()}. All right reserved.</span>
        //             </div>
        //             <div className="d-flex align-items-center justify-content-end w-25">
        //                 <Button size="sm" variant="facebook" className="me-2">
        //                     <i className="fab fa-facebook-f" />
        //                 </Button>
        //                 {/* <Button size="sm" variant="dark" className="mx-2 mb-2">
        //                     <i className="fa-brands fa-twitter" />
        //                 </Button> */}
        //                 <Button size="sm" variant="instagram">
        //                     <i className="fab fa-instagram" />
        //                 </Button>
        //             </div>
        //         </CDBBox>
        //     </Container>
        // </CDBFooter>
        <></>
    );
};