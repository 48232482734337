import React from "react";
import { Container } from "react-bootstrap";
import { useStateContext } from "../../../reducers";
import {ReactComponent as TestLogo} from '../../../assets/svg/test.svg';
import {ReactComponent as TestWorkLogo} from '../../../assets/svg/test-work.svg';
import {ReactComponent as TestScoreLogo} from '../../../assets/svg/test-score.svg';
import MenuCard from "../../../components/MenuCard";
import { useNavigate } from "react-router-dom";

function View(props) {
    const { menuState: {showSidebar} } = useStateContext();
    const navigate = useNavigate();
    const urlPrefix = "/evaluation";

    return (
        <Container fluid className={`m-0 p-0 p-3 d-flex flex-wrap justify-content-center align-items-center vh-75 main-container${showSidebar ? " crop" : ""}`}>
            <MenuCard
                menuIcon={<TestLogo className="menu-card-icon" />}
                title="Pengaturan Ujian"
                subtitle="Pengaturan Ujian Siswa"
                description="Gunakan menu ini untuk memasukkan data soal ujian."
                onClick={() => navigate(`${urlPrefix}/tests?step=0`)}
            />
            <MenuCard
                menuIcon={<TestWorkLogo className="menu-card-icon" />}
                title="Pengerjaan Ujian"
                subtitle="Pengerjaan Ujian Siswa"
                description="Gunakan menu ini untuk mengerjakan soal ujian."
                onClick={() => navigate(`${urlPrefix}/preparations?step=1`)}
            />
            <MenuCard
                menuIcon={<TestScoreLogo className="menu-card-icon" />}
                title="Penilaian Ujian"
                subtitle="Penilaian Ujian Siswa"
                description="Gunakan menu ini untuk menilai atau melihat hasil ujian."
                onClick={() => navigate(`${urlPrefix}/check`)}
            />
        </Container>
    );
}

export default View;
