import React, { useMemo } from "react";
import { TextBox } from '@syncfusion/ej2-inputs';
import { ComboBox } from '@syncfusion/ej2-dropdowns';
import { DatePicker } from '@syncfusion/ej2-calendars';
import { Image, OverlayTrigger, Popover } from "react-bootstrap";

export function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter, Header },
}) {
    // const count = preFilteredRows.length;

    // console.log({Header});
  
    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Cari ${Header.toLowerCase()}...`}
            className="w-100 border-0 border-bottom bg-transparent round grid-filter"
        />
    )
};

export const updateCell = (ref, rowIndex, dest, value) => {
    // console.log({ref, rowIndex, value, dest});
    ref.updateCell(rowIndex, dest, value);
    // ref.saveCell();
};

export const defaultToolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
export const editOnlyToolbarOptions = ['Edit', 'Update', 'Cancel'];

export const textareaParams = () => {
    let textElem;
    let textObj;

    return {
        create: () => {
            textElem = document.createElement('input');
            return textElem;
        },
        read: () => {
            return textObj ? textObj.value : null;
        },
        destroy: () => {
            if (textObj)
                textObj.destroy();
        },
        write: (args) => {
            textObj = new TextBox({
                // multiline: true,
                value: args.rowData[args.column.field],
                floatLabelType: 'Auto',
            });
            textObj.appendTo(textElem);
            textElem.addEventListener("input", function() {
                textObj.value = textElem.value;
            }.bind(this));
        }
    }
};

export const datetimeParams = () => {
    let dateElem;
    let dateObj;

    return {
        create: () => {
            dateElem = document.createElement('input');
            return dateElem;
        },
        read: (args) => {
            // console.log({args});
            return dateObj ? dateObj.value : null;
        },
        destroy: () => {
            if (dateObj)
                dateObj.destroy();
        },
        write: (args) => {
            // console.log({args});
            dateObj = new DatePicker({
                value: args.rowData[args.column.field],
                format: "dd-MM-yyyy",
                maskPlaceholder: "DD-MM-YYYY",
                allowEdit: false,
            });
            dateObj.appendTo(dateElem);
        }
    }
};

export const numericEditParams = (gridRef, onChange, min = 0) => {
    let numberElem;
    let numberObj;

    return {
        create: () => {
            numberElem = document.createElement('input');
            return numberElem;
        },
        read: (args) => {
            return numberObj ? Number(numberObj.value) : (!args.value || args.value === "" ? null : Number(args.value));
        },
        // destroy: () => {
        //     if (numberObj)
        //         numberObj.destroy();
        // },
        write: (args) => {
            const rowData = args.rowData;
            const rowIndex = gridRef.current.getRowInfo(args.row).rowIndex;
            numberObj = new TextBox({
                type: "number",
                value: args.rowData[args.column.field],
                floatLabelType: 'Auto',
                change: (editArgs) => {
                    // console.log({editArgs, args});
                    if (onChange)
                        onChange(editArgs, rowData, rowIndex);
                },
                min
            });
            numberObj.appendTo(numberElem);
            numberElem.addEventListener("input", function(e) {
                numberObj.value = e.target.value ? Number(e.target.value) : null;
            }.bind(this));
        }
    }
};

export const numericFloatEditParams = (gridRef, onChange, min = 0) => {
    let numberElem;
    let numberObj;

    return {
        create: () => {
            numberElem = document.createElement('input');
            return numberElem;
        },
        read: (args) => {
            return numberObj ? Number(numberObj.value) : (!args.value || args.value === "" ? null : Number(args.value));
        },
        // destroy: () => {
        //     if (numberObj)
        //         numberObj.destroy();
        // },
        write: (args) => {
            const rowData = args.rowData;
            const rowIndex = gridRef.current.getRowInfo(args.row).rowIndex;
            numberObj = new TextBox({
                type: "text",
                value: args.rowData[args.column.field],
                floatLabelType: 'Auto',
                change: (editArgs) => {
                    // console.log({editArgs, args});
                    if (onChange)
                        onChange(editArgs, rowData, rowIndex);
                },
            });
            numberObj.appendTo(numberElem);
            numberElem.addEventListener("input", function(e) {
                // console.log({value: e.target.value});
                numberObj.value = e.target.value ? e.target.value.replace(/[^0-9 \.]/, '') : null;
            }.bind(this));
            numberElem.addEventListener("blur", function(e) {
                // console.log({value: e.target.value, numberObj});
                numberObj.value = numberObj.previousValue ? parseFloat(numberObj.previousValue).toFixed(2) : null;
            }.bind(this));
        }
    }
};

export const comboboxEditParams = (gridRef, dataSource, fields, onChange, min = 0) => {
    let comboboxElem;
    let comboboxObj;

    return {
        create: () => {
            comboboxElem = document.createElement('input');
            // gridRef.current.appendChild(comboboxElem);
            return comboboxElem;
        },
        read: (args) => {
            return comboboxObj ? comboboxObj.value : null;
        },
        destroy: () => {
            if (comboboxObj)
                comboboxObj.destroy();
        },
        write: (args) => {
            const rowData = args.rowData;
            const rowIndex = gridRef.current.getRowInfo(args.row).rowIndex;
            // console.log({value: rowData[args.column.field], field: args.column.field});
            comboboxObj = new ComboBox({
                dataSource: dataSource,
                value: args.rowData[args.column.field],
                allowFiltering: true,
                change: (comboArgs) => {
                    // console.log("changed", {comboArgs});
                    onChange(comboArgs.itemData, args.column.field, rowData, rowIndex);
                },
                blur: (comboArgs) => {
                    // console.log("blur", {comboArgs});
                },
                fields: fields,
            });
            comboboxObj.appendTo(comboboxElem);
        }
    }
};

export const GridImageTemplate = (props) => {
    const {
        img,
    } = props;

    const popover = useMemo(() => (
        !img ?
        <></>
        :
        <Popover>
            <Popover.Body className="img-tooltip p-0">
                <Image alt={`${img.name}`} src={img.base64} className="img-fluid img-thumbnail" />
            </Popover.Body>
        </Popover>
    ), [img]);

    return (
        <div
            className={`w-100 h-100 text-center align-middle ${!img ? "text-danger" : "cursor-pointer"}`}
        >
            <OverlayTrigger placement="right" overlay={popover} trigger="click">
                <i className="fas fa-image"></i>
            </OverlayTrigger>
        </div>
    );
};
