import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from 'prop-types';

function MenuCard(props) {
    const {
        menuIcon,
        title,
        subtitle,
        description,
        onClick
    } = props;
    
    return (
        <Card
            className="menu-card m-0 m-3 my-5 shadow border-0"
            style={{ width: '25rem', height: '20rem' }}
            onClick={onClick}
        >
            <Card.Header className="m-0 p-0 w-100 d-flex flex-wrap justify-content-center align-items-center border-0 menu-card-icon-container">
            {
                menuIcon
            }
            </Card.Header>
            <Card.Body className="m-0 p-0 px-4 pt-2 pb-4 border-2">
                <Card.Title><h2 className="text-center fw-bold text-main">{title}</h2></Card.Title>
                <Card.Subtitle className="mt-2 mb-4 text-muted text-center"><h5>{subtitle}</h5></Card.Subtitle>
                <span className="text-break text-justify">{description}</span>
            </Card.Body>
        </Card>
    );
}

MenuCard.propTypes = {
    menuIcon: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default MenuCard;
