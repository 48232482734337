import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { useExpanded, useFilters, useFlexLayout, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import Pager from './Pager';
import { useWindowDimensions } from '../utils/Common';
import { DefaultColumnFilter } from '../utils/Grid';
import { useStateContext } from '../reducers';

function DefaultGrid(props) {
    const {
        columns,
        url,
        additionalParams,
        initData,
        reload,
        rowColorFunction,
        reloadCallback,
        initialFilters,
        initialSortBy,
        showPager,
        hidePageSizeSelector,
        defaultPageSize,
    } = props;

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [totalData, setTotalData] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState([]);
    const [sortBy, setSortBy] = useState([]);
    const [refs, setRefs] = useState([]);
    const skipPageResetRef = useRef();
    const { height } = useWindowDimensions();
    const { apiCaller } = useStateContext();

    const loadData = useCallback(() => {
        skipPageResetRef.current = true;
        // console.log({url, currentPage, pageSize});

        if (initData?.length)
        {
            setData([...initData]);
            setTotalData(initData.length);
            return;
        }

        if (!url)
            return;

        setLoading(reload >= 0);

        let stringFilters = "";

        filters?.forEach((f, idx) => {
            stringFilters += `&${f.id}=${f.value}`;
        });

        const stringSortBy = sortBy?.length ? `&sort-${sortBy[0].id}=${sortBy[0].desc ? "desc" : "asc"}` : "";

        apiCaller.get(`${url}?skip=${(currentPage - 1) * pageSize}&take=${pageSize}${additionalParams !== "" ? `&${additionalParams}` : ""}${stringFilters}${stringSortBy}`)
            .then((response) => {
                // console.log({response});
                const {data: {data: responseData, total}} = response;
                // console.log({responseData, total});
                setData(responseData);
                setTotalData(total);

                if (reloadCallback)
                    reloadCallback(responseData);
            })
            .catch((error) => {
                console.log({error});
                setData([]);
            })
            .finally(() => {
                setLoading(false);
            })
        ;
    }, [url, currentPage, pageSize, reloadCallback, initData, additionalParams, apiCaller, filters, sortBy, reload]);

    useEffect(() => {
        loadData();
    }, [url, currentPage, pageSize, reload, additionalParams, filters, sortBy]);

    useEffect(() => {
        // console.log({columns, data});
        if (!data.length)
        {
            setRefs([]);
            return;
        }

        const newRefs = [];
        data.forEach((d, idx) => {
            columns.forEach((c, c_idx) => {
                newRefs.push({
                    row_id: idx,
                    col_id: c_idx,
                    ref: createRef()
                });
            });
        });
        setRefs([...newRefs]);
    }, [columns, data]);

    const filterTypes = useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    );

    const defaultColumn = useMemo(() => ({
        width: 150,
        filter: "text",
        disableSortBy: false,
        Filter: DefaultColumnFilter
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state
    } = useTable(
        {
            columns,
            data,
            initialState: {
                filters: initialFilters,
                sortBy: initialSortBy,
            },
            filterTypes,
            defaultColumn,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );

    useEffect(() => {
        if (!state?.filters?.length)
            setFilters([]);
        else
            setFilters([...state.filters]);
    }, [state?.filters]);

    useEffect(() => {
        if (!state?.sortBy?.length)
            setSortBy([]);
        else
            setSortBy([...state.sortBy]);
    }, [state?.sortBy]);

    const pager = useMemo(() => (
        <Pager
            pageSizeChange={(size) => setPageSize(size)}
            currentPageSize={pageSize}
            pageChange={(page) => setCurrentPage(page)}
            currentPage={currentPage}
            rowNumber={totalData}
            hidePageSizeSelector={hidePageSizeSelector}
        />
    ), [pageSize, currentPage, totalData, hidePageSizeSelector]);

    return (
        <>
            <Table bordered hover responsive size="sm" {...getTableProps()}>
                <thead className="bg-grid-header" style={{display: "table", width: "calc(100% - 5px)"}}>
                    {headerGroups.map((headerGroup, idx) => (
                        <>
                            <tr {...headerGroup.getHeaderGroupProps()} key={`row-header-${idx}`}>
                                {headerGroup.headers.map((column, c_idx) => {
                                    // console.log({column});
                                    return (
                                        <th {...column.getHeaderProps()} key={`row-header_cell-${idx}-${c_idx}`} className={`px-2 font-sm-custom d-flex flex-column${c_idx === 0 ? " left-corner" : (c_idx === headerGroup.headers.length - 1 ? " right-corner" : "")}`}>
                                            <div {...column.getHeaderProps(column.getSortByToggleProps())} className="w-100 d-flex flex-row justify-content-between">
                                                {column.render('Header')}
                                                <span className="ms-2">
                                                    {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? (<i className="fas fa-chevron-down" />)
                                                        : (<i className="fas fa-chevron-up" />)
                                                    : ''}
                                                </span>
                                            </div>
                                            <div className="mt-2 w-100">{column.canFilter && column.Filter ? column.render('Filter') : null}</div>
                                        </th>
                                    );
                                })}
                            </tr>
                        </>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} className="w-100" style={{display: "block", maxHeight: height >= 992 ? "50vh" : height >= 800 ? "41vh" : height >= 540 ? "28vh" : "18vh", overflowY: "scroll"}}>
                {
                    loading ?
                    <tr className="w-100 d-flex flex-wrap justify-content-center align-items-center">
                        <td colSpan={columns.length} className="font-sm-custom w-100 d-flex flex-wrap justify-content-center align-items-center">
                            <Spinner
                                as="span"
                                animation="border"
                                variant="secondary"
                            />
                        </td>
                    </tr>
                    :
                    rows.length ?
                    rows.map((row, idx) => {
                        prepareRow(row);
                        const rowStyle = rowColorFunction(row.original);
                        // console.log({row, rowStyle});

                        return (
                            <tr {...row.getRowProps()} key={`row-${idx}`} className="w-100" style={{display: "table", ...rowStyle}}>
                                {row.cells.map((cell, c_idx) => {
                                    // console.log({cell, refs});
                                    const currentRef = refs.filter(r => r.row_id === idx && r.col_id === c_idx)[0] ? refs.filter(r => r.row_id === idx && r.col_id === c_idx)[0].ref : null;
                                    const cellKey = `row-cell-${idx}-${c_idx}`;

                                    if (!cell.column.clickable)
                                        return (
                                            <td {...cell.getCellProps()} key={cellKey} className={`px-2 font-sm-custom${cell.column && cell.column.centered ? " text-center" : ""} text-break`} ref={currentRef}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className="px-2 font-sm-custom cursor-pointer"
                                            ref={currentRef}
                                            onClick={(e) => cell.column.onClick(currentRef, row, e.target)}
                                            key={cellKey}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        );
                    })
                    :
                    <tr className="w-100 d-flex flex-wrap justify-content-center align-items-center">
                        <td colSpan={columns.length} className="font-sm-custom w-100 d-flex flex-wrap justify-content-center align-items-center">
                            <i>Data tidak ditemukan</i>
                        </td>
                    </tr>
                }
                </tbody>
            </Table>
        {
            showPager &&
            pager
        }
        </>
    );
}

DefaultGrid.propTypes = {
    columns: PropTypes.array.isRequired,
    url: PropTypes.string,
    reload: PropTypes.number.isRequired,
    rowColorFunction: PropTypes.func,
    reloadCallback: PropTypes.func,
    initData: PropTypes.array,
    initialFilters: PropTypes.array,
    initialSortBy: PropTypes.array,
    showPager: PropTypes.bool,
    additionalParams: PropTypes.string,
    hidePageSizeSelector: PropTypes.bool,
    defaultPageSize: PropTypes.number,
};

DefaultGrid.defaultProps = {
    reload: 0,
    rowColorFunction: () => ({}),
    initData: [],
    initialFilters: [],
    initialSortBy: [],
    showPager: true,
    additionalParams: "",
    hidePageSizeSelector: false,
    defaultPageSize: 10,
};

export default DefaultGrid;