import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
// import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import Select from 'react-select';

const LoadingIndicator = () => {
    return (
        <Spinner animation="border" role="status" size="sm" variant="secondary" className="mx-2" />
    );
};

function SearchInput(props) {
    const {
        title,
        onChange,
        loadOptions,
        valueField,
        labelField,
        required,
        defaultValue,
        showTitle,
        disabled,
        shouldReload,
    } = props;

    const [ options, setOptions ] = useState([]);
    const [ selectedOption, setSelectedOption ] = useState(null);
    const [ searchValue, setSearchValue ] = useState("");
    const [ oldSearchValue, setOldSearchValue ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ isReload, setIsReload ] = useState(shouldReload);

    useEffect(() => {
        // console.log("rendered", {defaultValue, shouldReload});
        if (!loadOptions || !valueField || !labelField)
        {
            setOptions([]);
            return;
        }

        setLoading(true);
        loadOptions(
            defaultValue?.label ?? "",
            valueField,
            labelField,
            (data) => {
                setOptions([...data]);
                // console.log({data, label: defaultValue?.label});
                if (data.length > 0 && defaultValue)
                {
                    setSelectedOption({...data.filter(d => d.value === defaultValue.value)[0]});
                    setLoading(false);
                    return;
                }
                
                if (shouldReload !== isReload)
                {
                    setSelectedOption(null);
                    setLoading(false);
                    setIsReload(shouldReload);
                    return;
                }

                setLoading(false);
            },
            () => setLoading(false),
        );
    }, [defaultValue, shouldReload, isReload]);

    useEffect(() => {
        // console.log({selectedOption});
        if (!onChange)
            return;

        onChange(selectedOption);
    }, [selectedOption]);

    const handleInputChanged = useCallback((val) => {
        // console.log({val});
        setSearchValue(val);
    }, []);

    const handleValueChanged = useCallback((val) => {
        // console.log({val});
        setSelectedOption({...val});
    }, []);

    useEffect(() => {
        if (oldSearchValue === searchValue)
            return;

        setLoading(true);
        setOldSearchValue(searchValue);
        // handleInputChanged(searchValue);
        loadOptions(searchValue, valueField, labelField, (data) => {
            setOptions([...data]);
            setLoading(false);
        });
    }, [searchValue]);

    const styles = useMemo(() => ({
        menu: (base) => ({ ...base, position: 'relative', maxHeight: "300px", overflowY: "auto" }),
    }), []);

    const selectObj = useMemo(() => {
        // console.log({options, selectedOption, disabled, styles});
        return (
            <Select
                options={options}
                onInputChange={handleInputChanged}
                onChange={handleValueChanged}
                value={selectedOption}
                isDisabled={disabled}
                styles={styles}
                components={{LoadingIndicator}}
                isLoading={loading}
            />
        );
    }, [loading, options, handleValueChanged, handleInputChanged, selectedOption, disabled, styles]);

    if (!showTitle)
        return (<>{selectObj}</>);

    return (
        <Form.Group
            className="mb-3 py-0"
        >
            <Form.Label>
                {title}:
            {
                required ?
                <>{" "}<span className="text-danger">*</span></>
                :
                null
            }
            </Form.Label>
            {selectObj}
        </Form.Group>
    );
}

SearchInput.propTypes = {
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    loadOptions: PropTypes.func.isRequired,
    valueField: PropTypes.string.isRequired,
    labelField: PropTypes.string.isRequired,
    required: PropTypes.bool,
    defaultValue: PropTypes.object,
    showTitle: PropTypes.bool,
    disabled: PropTypes.bool,
    shouldReload: PropTypes.number,
};

SearchInput.defaultProps = {
    required: false,
    defaultValue: null,
    showTitle: true,
    disabled: false,
    shouldReload: 0,
};

export default SearchInput;