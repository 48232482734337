import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TooltipButton from "../../../components/TooltipButton";
import { useStateContext } from "../../../reducers";
import { useLocation } from "react-router-dom";
import DefaultGrid from "../../../components/DefaultGrid";

function ScoreCheckStudentResults(props) {
    const location = useLocation();
    const [ questionParams, setQuestionParams ] = useState(null);
    const [ reload, setReload ] = useState(0);
    const { menuState: {showSidebar} } = useStateContext();
    
    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(location.search));
        setQuestionParams({
            ...params
        });
    }, [location]);

    const columns = useMemo(() => [
        {
            Header: "Soal",
            accessor: "soal",
            disableFilters: true,
            minWidth: 300,
            width: 300,
        },
        {
            Header: "Jawaban",
            accessor: "jawaban",
            disableFilters: true,
            maxWidth: 100,
            width: 100,
        },
        {
            Header: "Nilai Max",
            accessor: "nilai_max",
            disableFilters: true,
            maxWidth: 80,
            width: 80,
        },
        {
            Header: "Nilai",
            accessor: "nilai",
            disableFilters: true,
            maxWidth: 80,
            width: 80,
        },
        {
            Header: "",
            id: "action",
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <TooltipButton
                            title="Update Nilai"
                            size="sm"
                            variant="outline-primary"
                            onClick={() => {}}
                        >
                            <i className="fas fa-edit" />
                        </TooltipButton>
                    </div>
                )
            },
            maxWidth: 50,
        },
    ], []);

    const grid = useMemo(() => {
        if (!questionParams?.testId || !questionParams?.studentId)
            return null;

        return (
            <DefaultGrid
                columns={columns}
                url="tests/results/student"
                reload={reload}
                additionalParams={`testId=${questionParams?.testId}&studentId=${questionParams?.studentId}`}
            />
        );
    }, [reload, columns, questionParams?.testId, questionParams?.studentId]);

    return (
        <Container fluid className={`m-0 p-0 p-3 main-container${showSidebar ? " crop" : ""}`}>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Nama Siswa</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.studentName ?? "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Kelas</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.className ?? "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Pelajaran</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.subjectName ?? "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Tanggal</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.testDate ? moment(questionParams.testDate).format("DD-MM-YYYY") : "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Durasi</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.duration ?? "-"} menit</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-4">
                <Col lg={12} className="m-0 p-0">
                    <TooltipButton
                        id="reload-questions"
                        onClick={() => setReload(reload === 1 ? 0 : 1)}
                        variant="outline-dark"
                        size="sm"
                        title="Refresh Data"
                        className="ms-1"
                    >
                        <i className="fas fa-sync" />
                    </TooltipButton>
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-4">
                <Col lg={12} className="m-0 p-0">
                {
                    grid
                }
                </Col>
            </Row>
        </Container>
    );
};

export default ScoreCheckStudentResults;
