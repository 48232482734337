import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import TooltipButton from "../../../components/TooltipButton";
import { useStateContext } from "../../../reducers";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/Loader";

function Answers(props) {
    const location = useLocation();
    const [ questionParams, setQuestionParams ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ selectedQuestion, setSelectedQuestion ] = useState(-1);
    const { apiCaller, menuState: {showSidebar} } = useStateContext();
    
    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(location.search));
        setQuestionParams({
            ...params
        });
    }, [location]);

    const loadData = useCallback(() => {
        if (!questionParams)
            return;

        setLoading(true);

        apiCaller.get(`tests/questions/work?testId=${questionParams.testId}`)
            .then((response) => {
                const result = [...response.data.data];
                setData(result);
                setSelectedQuestion(result.length ? 0 : -1);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [apiCaller, questionParams]);

    useEffect(() => loadData(), [questionParams]);

    // useEffect(() => console.log({data}), [data]);

    const questionList = useMemo(() => {
        return (
            <Accordion defaultActiveKey={selectedQuestion}>
            {
                data?.map((d, idx) => {
                    return (
                        <Accordion.Item key={`question_${idx}`} eventKey={idx} className="accordion-questions">
                            <Accordion.Header className="p-0" onClick={() => setSelectedQuestion(idx)}>
                                <div className="w-100"><span>{idx + 1}.</span><span className="ms-3">{d.soal}</span></div>
                            </Accordion.Header>
                            <Accordion.Body eventKey={idx}>
                            {
                                d.tipe_soal === "Pilihan Ganda" ?
                                <Form>
                                {
                                    d.detail.map((dd, d_idx) => {
                                        return (
                                            <Form.Check
                                                name={`question_opt_${idx}`}
                                                type="radio"
                                                key={`question_opt_${d_idx}`}
                                                id={`question_opt_${d_idx}`}
                                                label={dd.pilihan}
                                            />
                                        );
                                    })
                                }
                                </Form>
                                :
                                <Form.Control
                                    as="textarea"
                                    name="kode"
                                    rows={7}
                                    autoComplete="off"
                                />
                            }
                            </Accordion.Body>
                        </Accordion.Item>
                    );
                })   
            }
            </Accordion>
        );
    }, [data, selectedQuestion]);

    return (
        <Container fluid className={`m-0 p-0 p-3 main-container${showSidebar ? " crop" : ""}`}>
        {
            loading && <Loader />
        }
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Kelas</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.className ?? "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Pelajaran</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.subjectName ?? "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Tanggal</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.testDate ? moment(questionParams.testDate).format("DD-MM-YYYY") : "-"}</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={4} md={6} sm={12} className="m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col xs={3} className="m-0 p-0">
                            <span>Durasi</span>
                        </Col>
                        <Col xs={9} className="m-0 p-0">
                            <span className="text-break">: <b>{questionParams?.duration ?? "-"} menit</b></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-4">
                <Col lg={12} className="m-0 p-0">
                {
                    questionList
                }
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-4">
                <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                    <Button
                        variant="primary"
                        size="sm"
                    >
                        <i className="fas fa-save me-2" />Submit
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Answers;
