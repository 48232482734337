import React from "react";
import { Container } from "react-bootstrap";
import TooltipButton from "../../../components/TooltipButton";
import { useStateContext } from "../../../reducers";

function Common(props) {
    const { authState: {active_year} } = useStateContext();

    return (
        <Container fluid className="m-0 p-0 d-flex flex-wrap justify-content-start align-items-center">
            <span>
                Tahun Ajaran:
                <b className="ms-3">{active_year}</b>
            </span>
            <TooltipButton
                title="Ubah"
                size="sm"
                variant="primary"
                className="ms-4"
            >
                <i className="fas fa-edit" />
            </TooltipButton>
        </Container>
    );
};

export default Common;