import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import Loader from "../../../components/Loader";
import SearchInput from "../../../components/SearchInput";
import TooltipButton from "../../../components/TooltipButton";
import { useStateContext } from "../../../reducers";

function UserEditor(props) {
    const {
        onHide,
        data,
        onSave
    } = props;

    const [itemInEdit, setItemInEdit] = useState(data);
    const [loading, setLoading] = useState(false);
    const [defaultEmployee, setDefaultEmployee] = useState(null);
    const [defaultRole, setDefaultRole] = useState(null);
    const [disableSave, setDisableSave] = useState(true);
    const { apiCaller } = useStateContext();

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    useEffect(() => {
        setDisableSave(!itemInEdit || !itemInEdit.username || !itemInEdit.ucode_karyawan || !itemInEdit.ucode_role);
    }, [itemInEdit]);

    useEffect(() => {
        setDefaultEmployee(!data?.ucode_karyawan ? null : {
            value: data.ucode_karyawan,
            label: data.nama_karyawan,
        });

        setDefaultRole(!data?.ucode_role ? null : {
            value: data.ucode_role,
            label: data.nama_role,
        });
    }, [data]);

    const handleSyncEmail = useCallback(() => {
        setLoading(true);
        apiCaller.get(`employees/search/email?id=${itemInEdit.ucode_karyawan}`)
            .then((response) => {
                // console.log({response});
                setItemInEdit({
                    ...itemInEdit,
                    username: response.data.data.email,
                });
            })
            .catch((error) => {
                console.log({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [apiCaller, itemInEdit]);

    const handleSave = useCallback(() => {
        if (!itemInEdit)
            return;
        
        setLoading(true);
        // console.log({itemInEdit});
        const dataToSend = {
            ucode: itemInEdit.ucode ?? null,
            ucode_karyawan: itemInEdit.ucode_karyawan,
            username: itemInEdit.username,
            ucode_role: itemInEdit.ucode_role,
            sts: itemInEdit.ucode ? itemInEdit.sts : 0,
        }
        const request = dataToSend && !dataToSend.ucode ? apiCaller.post(`users`, JSON.stringify(dataToSend)) : apiCaller.put(`users/${dataToSend.ucode}`, JSON.stringify(dataToSend));

        request
            .then((response) => {
                // console.log({response});
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.log({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, apiCaller, onSave]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="4" md="12" className="mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide()}
                    >
                        Batal
                    </Button>
                </Col>
                <Col xl="2" lg="4" md="12" className="mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [disableSave, onHide, handleSave]);
    
    const loadDataEmployees = useCallback(async (val, valueField, labelField, callback) => {
        // console.log({val});
        if (!val)
            val = "";

        apiCaller.get(`employees/search/name${val !== "" ? `?name=${val}` : ""}`)
            .then(response => {
                // console.log({response});
                callback(response.data.data.map(item => {
                    return {
                        value: item[valueField],
                        label: item[labelField],
                        username: item.email,
                    };
                }));
            })
            .catch((error) => {
                console.log({error});
            })
        ;
    }, [apiCaller]);

    const onEmployeeSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            ucode_karyawan: val?.value,
            username: val?.username,
        });
    }, [itemInEdit]);
    
    const loadDataRoles = useCallback(async (val, valueField, labelField, callback) => {
        if (!val)
            val = "";

        return apiCaller.get(`roles/search/name?name=${val}`)
            .then(response => {
                callback(response.data.data.map(item => {
                    return {
                        value: item[valueField],
                        label: item[labelField],
                    };
                }));
            })
            .catch((error) => {
                console.log({error});
            })
        ;
    }, [apiCaller]);

    const onRoleSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            ucode_role: val?.value,
        });
    }, [itemInEdit]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide()}
            title="User"
            titleDetails={data.ucode ? "Edit" : "Baru"}
            dialogClassName="modal-w-80"
            footer={footer}
        >
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="justify-content-md-center mb-3">
                    <Col lg="12" style={{fontSize: "0.75rem"}}>
                        <i>Isian dengan tanda <span className="text-danger">*</span> harus diisi.</i>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg="4" md="12" className="mb-3">
                        <SearchInput
                            title="Nama Karyawan"
                            valueField="ucode"
                            labelField="nama"
                            loadOptions={(val, valueField, labelField, callback) => loadDataEmployees(val, valueField, labelField, callback)}
                            onChange={(val) => onEmployeeSelected(val)}
                            defaultValue={defaultEmployee}
                            required={true}
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Username: <span className="text-danger">*</span>
                        </Form.Label>
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.username ? itemInEdit.username : ""}
                                name="username"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                                disabled={true}
                            />
                            <TooltipButton
                                title="Sync Email"
                                size="sm"
                                variant="outline-dark"
                                className="ms-2"
                                onClick={() => {
                                    handleSyncEmail();
                                }}
                                disabled={!itemInEdit?.ucode_karyawan}
                            >
                                <i className="fas fa-sync-alt" />
                            </TooltipButton>
                        </div>
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <SearchInput
                            title="Role"
                            valueField="ucode"
                            labelField="nama"
                            loadOptions={(val, valueField, labelField, callback) => loadDataRoles(val, valueField, labelField, callback)}
                            onChange={(val) => onRoleSelected(val)}
                            defaultValue={defaultRole}
                            required={true}
                        />
                    </Col>
                </Row>
            </Container>
        </CustomModal>
    );
};

export default UserEditor;