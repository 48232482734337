import React from "react";
import { Form, ButtonGroup, Button, Row, Col } from "react-bootstrap";
import { useWindowDimensions } from "../utils/Common";

function Pager(props) {
    const {
        pageSizeChange,
        currentPageSize,
        pageChange,
        currentPage,
        rowNumber,
        hidePageSizeSelector,
    } = props;

    const lastPage = rowNumber % currentPageSize > 0 ? parseInt(rowNumber / currentPageSize) + 1 : (rowNumber / currentPageSize);
    const canSubPage = currentPage > 1;
    const canAddPage = currentPage < lastPage;
    const {height, width} = useWindowDimensions();

    return (
        <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="w-50 p-0 d-flex align-items-center justify-content-start">
                    <Form.Text className="text-muted font-sm-custom" size="sm">
                        <i>{`Jumlah data: ${rowNumber}`}</i>
                    </Form.Text>
                </div>
                <div className="w-50 p-0 d-flex align-items-center justify-content-end">
                {
                    hidePageSizeSelector ?
                    null
                    :
                    <Form.Select
                        onChange={(e) => pageSizeChange(e.target.value)}
                        value={currentPageSize}
                        className="w-25 grid-title font-sm-custom"
                    >
                        <option id="1" className="grid-title" value={10}>10</option>
                        <option id="2" className="grid-title" value={25}>25</option>
                        <option id="3" className="grid-title" value={50}>50</option>
                        <option id="4" className="grid-title" value={100}>100</option>
                    </Form.Select>
                }
                    <ButtonGroup className="mx-2">
                        <Button
                            onClick={() => pageChange(rowNumber > 0 ? 1 : 0)}
                            disabled={currentPage <= 1}
                            variant="outline-dark"
                            size="sm"
                            className="font-sm-custom"
                        >
                            <i className="fas fa-angle-double-left font-sm-custom"></i>
                        </Button>
                        <Button
                            onClick={() => {
                                if(!canSubPage)
                                    return;

                                pageChange(currentPage - 1);
                            }}
                            disabled={currentPage <= 1}
                            variant="outline-dark"
                            size="sm"
                        >
                            <i className="fas fa-angle-left"></i>
                        </Button>
                        <Button
                            onClick={() => {
                                if(!canAddPage)
                                    return;

                                pageChange(currentPage + 1);
                            }}
                            disabled={currentPage >= lastPage}
                            variant="outline-dark"
                            size="sm"
                        >
                            <i className="fas fa-angle-right"></i>
                        </Button>
                        <Button
                            onClick={() => pageChange(lastPage)}
                            disabled={currentPage >= lastPage}
                            variant="outline-dark"
                            size="sm"
                        >
                            <i className="fas fa-angle-double-right"></i>
                        </Button>
                    </ButtonGroup>
                {
                    width >= 800 ?
                    <Form.Text className="text-muted" size="sm">
                        <i>{`halaman: ${currentPage} dari ${lastPage}`}</i>
                    </Form.Text>
                    :
                    null
                }
                </div>
        </div>
    );
}

export default Pager;
