export const menuInitialState = {showSidebar: false};

export default function menuReducer(state, action) {
    switch (action.type) {
        case 'toggle':
            console.log({action});
            return {showSidebar: action.showSidebar};
        default:
            throw new Error();
    }
}
