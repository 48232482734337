import React from "react";
import TemplateView from "../../TemplateView";

function View(props) {
    return (
        <TemplateView
            group="Administrasi"
            subgroup="Pembayaran"
        />
    );
};

export default View;