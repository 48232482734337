import React, { useCallback, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import SearchInput from "../../components/SearchInput";
import { useStateContext } from "../../reducers";

function Class(props) {
    const { apiCaller, menuState: {showSidebar} } = useStateContext();
    const [ selectedClass, setSelectedClass ] = useState(null);

    const loadDataClasses = useCallback((val, valueField, labelField, callback, errorCallback) => {
        apiCaller.get(`classes/search/name${val && val !== "" ? `?name=${val}` : ""}`)
            .then(response => {
                callback(response.data.data.map(item => {
                    return {
                        value: item[valueField],
                        label: item[labelField],
                    };
                }));
            })
            .catch((error) => {
                console.log({error});
                errorCallback();
            })
        ;
    }, [apiCaller]);

    const onClassSelected = useCallback((val) => {
        if (!val)
        {
            setSelectedClass(null);
            return;
        }

        setSelectedClass({
            ucode: val?.value,
            nama: val?.label,
        });
    }, []);

    return (
        <Container fluid className={`m-0 p-0 p-3 main-container${showSidebar ? " crop" : ""}`}>
            <Row className="m-0 p-0">
                <Col lg={3} md={6} sm={12} className="m-0 p-0 px-2">
                    <SearchInput
                        title="Kelas"
                        valueField="ucode"
                        labelField="nama"
                        loadOptions={(val, valueField, labelField, callback, errorCallback) => loadDataClasses(val, valueField, labelField, callback, errorCallback)}
                        onChange={(val) => onClassSelected(val)}
                        required={true}
                    />
                </Col>
            </Row>
            <Row className="m-0 p-0">
                <Col lg={12} className="m-0 p-0">
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-2">
                <Col lg={12} className="m-0 p-0">
                    <Form.Control
                        as="textarea"
                        rows={7}
                    />
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-4">
                <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                    <Button
                        variant="primary"
                        size="sm"
                    >
                        <i className="fas fa-paper-plane me-2" />Kirim Pengumuman
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Class;
