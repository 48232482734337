import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import Loader from "../../../components/Loader";
import TooltipLabel from "../../../components/TooltipLabel";
import { useStateContext } from "../../../reducers";

function RoleEditor(props) {
    const {
        onHide,
        data,
        onSave
    } = props;

    const [loading, setLoading] = useState(false);
    const [freeMenus, setFreeMenus] = useState([]);
    const [selectedFreeMenus, setSelectedFreeMenus] = useState([]);
    const [details, setDetails] = useState([]);
    const [selectedDetails, setSelectedDetails] = useState([]);
    const [disableSave, setDisableSave] = useState(true);
    const { authState, apiCaller } = useStateContext();
    const [itemInEdit, setItemInEdit] = useState({...data, level: !data.ucode ? (authState.level === 0 ? 0 : authState.level + 1) : data.level});
    
    // useEffect(() => console.log({authState}), [authState]);

    const loadDetail = useCallback(() => {
        setLoading(true);

        const apiCalls = [
            apiCaller.get(`roles/search/freemenu${data.ucode ? `?id=${data.ucode}` : ""}`)
        ];

        if (data.ucode)
            apiCalls.push(apiCaller.get(`roles/search/selectedmenu${data.ucode ? `?id=${data.ucode}` : ""}`));

        Promise.all(apiCalls)
            .then((responses) => {
                // console.log({responses});
                setFreeMenus(responses[0].data.data);

                if (data.ucode)
                    setDetails(responses[1].data.data);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [apiCaller, data]);

    useEffect(() => loadDetail(), [data]);

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    useEffect(() => {
        setDisableSave(!itemInEdit || !itemInEdit.nama || itemInEdit.level === null || (itemInEdit.level <= authState.level && authState.level > 0));
    }, [itemInEdit, authState]);

    const handleSave = useCallback(() => {
        if (!itemInEdit)
            return;
        
        setLoading(true);
        const dataToSend = {
            ...itemInEdit,
            details: details.map(d => ({
                ucode_menu: d.ucode_menu,
                can_add: d.can_add ? 1 : 0,
                can_edit: d.can_edit ? 1 : 0,
                can_delete: d.can_delete ? 1 : 0,
                see_other_div: d.see_other_div ? 1 : 0,
            })),
        };
        // console.log({dataToSend});
        const request = dataToSend && !dataToSend.ucode ? apiCaller.post(`roles`, JSON.stringify(dataToSend)) : apiCaller.put(`roles/${dataToSend.ucode}`, JSON.stringify(dataToSend));

        request
            .then((response) => {
                // console.log({response});
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.log({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, apiCaller, onSave, details]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="4" md="12" className="mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide()}
                    >
                        Batal
                    </Button>
                </Col>
                <Col xl="2" lg="4" md="12" className="mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [disableSave, onHide, handleSave]);

    const onAccessDetailChange = useCallback((ucode_menu, propName, checked) => {
        const newMenus = JSON.parse(JSON.stringify(details));
        const updatedMenu = newMenus.filter(m => m.ucode_menu === ucode_menu)[0];

        if (!updatedMenu)
            return;
        
        updatedMenu[propName] = checked;
        setDetails([...newMenus]);
    }, [details]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide()}
            title="Role"
            titleDetails={data.ucode ? "Edit" : "Baru"}
            footer={footer}
        >
        {
            loading ? <Loader /> : null
        }
            <Container>
                <Row className="justify-content-md-center mb-3">
                    <Col lg="12" style={{fontSize: "0.75rem"}}>
                        <i>Isian dengan tanda <span className="text-danger">*</span> harus diisi.</i>
                    </Col>
                </Row>
                <Row className="justify-content-start mb-3">
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Kode:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.kode ? itemInEdit.kode : ""}
                            name="kode"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Nama: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.nama ? itemInEdit.nama : ""}
                            name="nama"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Level: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="number"
                            min={authState.level === 0 ? 0 : authState.level + 1}
                            value={itemInEdit !== null && itemInEdit.level ? itemInEdit.level : (authState.level === 0 ? 0 : authState.level + 1)}
                            name="level"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="12" className="mb-3">
                        <Form.Label>
                            Hak Akses:
                        </Form.Label>
                        <Row className="m-0 p-0" style={{height: "200px"}}>
                            <Col lg="3" className="m-0 p-0 border rounded h-100">
                                <div className="w-100 p-2 font-sm-custom d-flex flex-wrap justify-content-between align-items-center">
                                    <span>Menu</span>
                                    <Button
                                        size="sm"
                                        variant="dark"
                                        onClick={() => {
                                            setSelectedFreeMenus([...freeMenus]);
                                        }}
                                    >
                                        Pilih Semua
                                    </Button>
                                </div>
                                <div className="w-100 p-2" style={{height: "150px", overflowY: "auto"}}>
                                {
                                    freeMenus.map((m, idx) => (
                                        <Row
                                            className="m-0 p-0 py-2 w-100 border-bottom font-sm-custom d-flex flex-wrap justify-content-center align-items-center"
                                            key={`free-menu-${idx}`}
                                        >
                                            <Col lg={2} className="m-0 p-0">
                                                <Form.Check
                                                    className="cursor-pointer"
                                                    onChange={({target: {checked}}) => {
                                                        if (checked)
                                                        {
                                                            setSelectedFreeMenus([
                                                                ...selectedFreeMenus,
                                                                {
                                                                    ...m
                                                                }
                                                            ]);
                                                            return;
                                                        }

                                                        const newMenus = JSON.parse(JSON.stringify(selectedFreeMenus));
                                                        const removedMenu = newMenus.filter(d => d.ucode_menu === m.ucode_menu)[0];

                                                        if (!removedMenu)
                                                            return;

                                                        const index = newMenus.indexOf(removedMenu);
                                                        newMenus.splice(index, 1);
                                                        setSelectedFreeMenus([...newMenus]);
                                                    }}
                                                    checked={selectedFreeMenus.filter(d => d.ucode_menu === m.ucode_menu).length > 0}
                                                />
                                            </Col>
                                            <Col lg={10} className="m-0 p-0">
                                                <span>
                                                    {m.nama_menu}
                                                </span>
                                            </Col>
                                        </Row>
                                    ))
                                }
                                </div>
                            </Col>
                            <Col lg="2" className="m-0 p-1 h-100 d-flex flex-column justify-content-center align-items-center">
                                <Button
                                    size="sm"
                                    onClick={() => {
                                        const newFreeMenus = [...freeMenus];
                                        const newDetails = [...details];

                                        selectedFreeMenus.forEach(m => {
                                            const selected = newFreeMenus.filter(d => d.ucode_menu === m.ucode_menu)[0];

                                            if (!selected)
                                                return;

                                            const index = newFreeMenus.indexOf(selected);
                                            // console.log({selected, index});
                                            const newSelected = {
                                                ...JSON.parse(JSON.stringify(selected)),
                                                can_add: false,
                                                can_edit: false,
                                                can_delete: false,
                                                see_other_div: false,
                                            };
                                            newFreeMenus.splice(index, 1);
                                            newDetails.push(newSelected);
                                        });

                                        // console.log({newFreeMenus});
                                        newFreeMenus.sort((a, b) => a.nama_menu < b.nama_menu ? -1 : 1);
                                        newDetails.sort((a, b) => a.nama_menu < b.nama_menu ? -1 : 1);
                                        setFreeMenus([...newFreeMenus]);
                                        setDetails([...newDetails]);
                                        setSelectedFreeMenus([]);
                                    }}
                                    disabled={!selectedFreeMenus?.length}
                                >
                                    <i className="fas fa-chevron-right" />
                                </Button>
                                <Button
                                    size="sm"
                                    className="mt-2"
                                    onClick={() => {
                                        const newFreeMenus = [...freeMenus];
                                        const newDetails = [...details];

                                        selectedDetails.forEach(m => {
                                            const selected = newDetails.filter(d => d.ucode_menu === m.ucode_menu)[0];

                                            if (!selected)
                                                return;

                                            const index = newDetails.indexOf(selected);
                                            // console.log({selected, index});
                                            const newSelected = {
                                                ...JSON.parse(JSON.stringify(selected)),
                                                can_add: false,
                                                can_edit: false,
                                                can_delete: false,
                                                see_other_div: false,
                                            };
                                            newDetails.splice(index, 1);
                                            newFreeMenus.push(newSelected);
                                        });

                                        // console.log({newFreeMenus});
                                        newFreeMenus.sort((a, b) => a.nama_menu < b.nama_menu ? -1 : 1);
                                        newDetails.sort((a, b) => a.nama_menu < b.nama_menu ? -1 : 1);
                                        setFreeMenus([...newFreeMenus]);
                                        setDetails([...newDetails]);
                                        setSelectedDetails([]);
                                    }}
                                    disabled={!selectedDetails?.length}
                                >
                                    <i className="fas fa-chevron-left" />
                                </Button>
                            </Col>
                            <Col lg="7" className="m-0 p-0 border h-100">
                                <Row
                                    className="m-0 p-0 w-100 font-sm-custom d-flex flex-wrap justify-content-center align-items-center"
                                >
                                    <Col lg={8} className="m-0 p-0">
                                        <div className="w-100 p-2 font-sm-custom d-flex flex-wrap justify-content-between align-items-center">
                                            <span>Menu Terpilih</span>
                                            <Button
                                                size="sm"
                                                variant="dark"
                                                onClick={() => {
                                                    const newDetails = JSON.parse(JSON.stringify(details));
                                                    newDetails.forEach((d, idx) => {
                                                        d.can_add =  details[idx].editor_can_add;
                                                        d.can_edit = details[idx].editor_can_edit;
                                                        d.can_delete = details[idx].editor_can_delete;
                                                        d.see_other_div = details[idx].editor_see_other_div;
                                                    });
                                                    setDetails([...newDetails]);
                                                }}
                                            >
                                                Checklist Semua
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col lg={1} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                        <TooltipLabel
                                            title="Tambah Data"
                                        >
                                            <i className="fas fa-plus" />
                                        </TooltipLabel>
                                    </Col>
                                    <Col lg={1} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                        <TooltipLabel
                                            title="Ubah Data"
                                        >
                                            <i className="fas fa-edit" />
                                        </TooltipLabel>
                                    </Col>
                                    <Col lg={1} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                        <TooltipLabel
                                            title="Hapus Data"
                                        >
                                            <i className="fas fa-trash" />
                                        </TooltipLabel>
                                    </Col>
                                    <Col lg={1} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                        <TooltipLabel
                                            title="Lihat Semua Divisi"
                                        >
                                            <i className="fas fa-eye" />
                                        </TooltipLabel>
                                    </Col>
                                </Row>
                                <div className="w-100 p-2" style={{height: "150px", overflowY: "auto"}}>
                                {
                                    details?.map((m, idx) => (
                                        <Row
                                            className="m-0 p-0 py-2 w-100 border-bottom font-sm-custom"
                                            key={`selected-menu-${idx}`}
                                        >
                                            <Col lg={1} className="m-0 p-0">
                                                <Form.Check
                                                    className="cursor-pointer"
                                                    onChange={({target: {checked}}) => {
                                                        if (checked)
                                                        {
                                                            setSelectedDetails([
                                                                ...selectedDetails,
                                                                {
                                                                    ...m
                                                                }
                                                            ]);
                                                            return;
                                                        }

                                                        const newMenus = JSON.parse(JSON.stringify(selectedDetails));
                                                        const removedMenu = newMenus.filter(d => d.ucode_menu === m.ucode_menu)[0];

                                                        if (!removedMenu)
                                                            return;

                                                        const index = newMenus.indexOf(removedMenu);
                                                        newMenus.splice(index, 1);
                                                        setSelectedDetails([...newMenus]);
                                                    }}
                                                    checked={selectedDetails.filter(d => d.ucode_menu === m.ucode_menu).length > 0}
                                                />
                                            </Col>
                                            <Col lg={7} className="m-0 p-0">
                                                <span>
                                                    {m.nama_menu}
                                                </span>
                                            </Col>
                                            <Col lg={1} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                            {
                                                details[idx].editor_can_add ?
                                                <Form.Check
                                                    className="cursor-pointer"
                                                    onChange={({target: {checked}}) => onAccessDetailChange(m.ucode_menu, "can_add", checked)}
                                                    checked={details[idx].can_add}
                                                />
                                                :
                                                null
                                            }
                                            </Col>
                                            <Col lg={1} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                            {
                                                details[idx].editor_can_edit ?
                                                <Form.Check
                                                    className="cursor-pointer"
                                                    onChange={({target: {checked}}) => onAccessDetailChange(m.ucode_menu, "can_edit", checked)}
                                                    checked={details[idx].can_edit}
                                                />
                                                :
                                                null
                                            }
                                            </Col>
                                            <Col lg={1} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                            {
                                                details[idx].editor_can_delete ?
                                                <Form.Check
                                                    className="cursor-pointer"
                                                    onChange={({target: {checked}}) => onAccessDetailChange(m.ucode_menu, "can_delete", checked)}
                                                    checked={details[idx].can_delete}
                                                />
                                                :
                                                null
                                            }
                                            </Col>
                                            <Col lg={1} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                            {
                                                details[idx].editor_see_other_div ?
                                                <Form.Check
                                                    className="cursor-pointer"
                                                    onChange={({target: {checked}}) => onAccessDetailChange(m.ucode_menu, "see_other_div", checked)}
                                                    checked={details[idx].see_other_div}
                                                />
                                                :
                                                null
                                            }
                                            </Col>
                                        </Row>
                                    ))
                                }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="12" className="mb-3">
                        <Form.Label>
                            Keterangan:
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            value={itemInEdit !== null && itemInEdit.keterangan ? itemInEdit.keterangan : ""}
                            name="keterangan"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            rows={5}
                        />
                    </Col>
                </Row>
            </Container>
        </CustomModal>
    );
};

export default RoleEditor;