import React from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { Menu } from '../utils/Menu';
import { useStateContext } from '../reducers';

function DefaultBreadcrumb(props) {
    const {
        mapper
    } = props;

    const pathName = useLocation().pathname;
    const location = useLocation().pathname.split("/");
    const { menuState: {showSidebar} } = useStateContext();

    if (location[location.length - 1] === "" || location[location.length - 1] === "print")
        return <></>;

    return (
        <Container fluid className={`p-0 m-0 mt-3 px-3 main-container${showSidebar ? " crop" : ""}`}>
            <Breadcrumb>
            {
                location.map((l, idx) => {
                    const currentMenu = Menu.filter(m => {
                        const menuPath = m.path.split("/");
                        return menuPath[menuPath.length - 1] === l;
                    })[0];
                    const joinedPath = location.filter((l, l_idx) => l_idx <= idx)?.join("/");
                    const currentMapped = mapper?.filter(m => m.url === joinedPath)[0];
                    const title = l === "" ? "" : (currentMenu?.title ?? currentMapped?.title ?? "");
                    const currentPath = joinedPath && joinedPath !== "" ? joinedPath : "/";
                    // console.log({l, currentMenu, currentMapped, joinedPath, currentPath, title});

                    return (
                        <Breadcrumb.Item
                            key={`breadcrumb_${idx}`}
                            active={idx === location.length - 1}
                            className={l === "" ? "" : "breadcrumb-separator"}
                            href={currentPath}
                        >
                        {
                            l === "" ?
                            <i className="fas fa-home text-default-custom" />
                            :
                            <span style={{textTransform: "capitalize"}} className={`${idx === location.length - 1 ? "fw-bold fw-bolder breadcrumb-title-active" : "text-default-custom"} text-nowrap`}>{title}</span>
                        }
                        </Breadcrumb.Item>
                    );
                })
            }
            </Breadcrumb>
        </Container>
    );
}

export default DefaultBreadcrumb;